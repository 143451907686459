import React, { useState } from 'react'
import { Link } from 'gatsby'

interface MenuProps {
  locale: string
}

const Menu = ({ locale }: MenuProps): JSX.Element => {
  const [isOpen, setOpen] = useState({ isMenuOpen: false, menuText: 'openMenu()' })
  //for the useOnClickOutside hook

  const checkMenu = (): void => {
    !isOpen.isMenuOpen
      ? setOpen({ isMenuOpen: true, menuText: 'closeMenu()' })
      : setOpen({ isMenuOpen: false, menuText: 'openMenu()' })
  }

  return (
    <div className="navContainer">
      <button onClick={checkMenu}>{isOpen.menuText}</button>
      <ul className={`navMenu${isOpen.isMenuOpen ? ' openMenu' : ''}`}>
        <Link to="/">
          <li>
            <span className="term" />
            HOME
          </li>
        </Link>
      </ul>
    </div>
  )
}

export default Menu
