import React from 'react'
import './indicator.scss'

interface Props {
  height?: number
  width?: number
  marginTop?: string,
  marginX?: string,
  bgColor?: string
  animation?: 'animateIndicator' | 'animateIndicator-onHover'
}

/*
* Default color is black.
* If the animation should only play with mouse over then the parent needs to have the class animationParent
*/
const Indicator = ({ bgColor = 'black', animation = 'animateIndicator', width = 3, height = 5, marginTop = 'px', marginX = 'px' }: Props) => (
  <span className={`absolute w-${width} 
    h-${height} 
    mt-${marginTop} 
    mx-${marginX} 
    bg-${bgColor + ' ' + animation}`} />)

export default Indicator
