import React from 'react'
import Menu from './Menu'
import './navigation.scss'

interface NavigationProps {
  locale: string
}

const Navigation = ({ locale }: NavigationProps): JSX.Element => {
  return (
    <nav className="fixed w-full pt-5 z-10 bg-white font-mono">
      <div className="nav-wrapper flex justify-between p-0 container-normal relative">
        <div className="w-1/3">
          <Menu locale={locale} />
        </div>
        <div className="w-1/3" />
        <button className="w-1/3 snipcart-checkout">
          <div className="float-right">
            openCart(
            <span className="snipcart-items-count">0</span>)
          </div>
        </button>
      </div>
    </nav>
  )
}

export default Navigation
