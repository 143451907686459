import React from 'react'
import Helmet from 'react-helmet'
import favicon from '../../../img/favicon-immajung.gif'

interface Props {
  metaDescription: string
  lang?: string
  title: string
  author?: string
}

const SEO = ({ metaDescription, lang = 'en', title, author = 'immajung' }: Props): JSX.Element => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    >
      <link rel="icon" href={favicon} type="image/gif" />
    </Helmet>
  )
}

export default SEO
