import React, { ReactNode } from 'react'
import './layout.scss'
import Navigation from '../../shared/navigation/Navigation'
import Footer from '../../shared/footer/Footer'
import { Cookie } from '../../shared/cookie/CookieConsent'

interface Props {
  children: ReactNode
  locale: string
}

const Layout = ({ children, locale }: Props): JSX.Element => {
  return (
    <>
      <Navigation locale={locale} />
      <Cookie />
      <main className="font-mono">{children}</main>
      <Footer locale={locale} />
    </>
  )
}

export default Layout
