import React, { useRef } from 'react'
import instagram from '../../../img/socialmedia/instagram.svg'
import facebook from '../../../img/socialmedia/facebook.svg'
import { Link } from 'gatsby'
import Indicator from '../../shared/consoleIndicator/Indicator'
import './footer.scss'
interface FooterProps {
  locale: string
}

const Footer = ({ locale }: FooterProps) => {
  const [visible, setVisible] = React.useState('')
  const [mobilevisible, setMobilevisible] = React.useState('')

  function onShow(e: string) {
    setVisible('fade-in w-full md:flex md:flex-wrap')
  }

  return (
    <footer className="container">
      <div id="footer-scroll-point" className="footer-background bg-black">
        <div className="text-blueScreenBgInverted h-32 footer-linkm">
          <div className="test p-1 text-xl">
            <a>EXPLORE</a>
          </div>
          <div className="text-3 p-1">
            <Link to="mailto:hello@immajung.de" className="underline-effect hover:text-blueScreenBgInverted">
              Contact
            </Link>
          </div>
          <div className="text-3 p-1">
            <Link to="/" className="underline-effect hover:text-blueScreenBgInverted">
              Shop
            </Link>
          </div>
        </div>
        <div className="text-blueScreenBgInverted h-32 footer-linkr">
          <div className="p-1 text-xl">
            <a>LEGAL</a>
          </div>
          <div className="text-3 p-1">
            <Link to="privacy" className="underline-effect hover:text-blueScreenBgInverted">
              Privacy
            </Link>
          </div>
          <div className="text-3 p-1">
            <Link to="imprint" className="underline-effect hover:text-blueScreenBgInverted">
              Imprint
            </Link>
          </div>
          <div className="text-3 p-1">
            <Link to="return" className="underline-effect hover:text-blueScreenBgInverted">
              Return
            </Link>
          </div>
          <div className="text-3 p-1">
            <Link to="shipping" className="underline-effect hover:text-blueScreenBgInverted">
              Shipping
            </Link>
          </div>
          <div className="text-3 p-1">
            <Link className="underline-effect hover:text-blueScreenBgInverted">Newsletter</Link>
          </div>
        </div>
        <div className="text-blueScreenBgInverted footer-linkl">
          <div className="text-3xl p-1">IMMAJUNG</div>
          <div className="text-3 p-1">
            <a>
              print("hello world")
              <Indicator bgColor="white" animation="animateIndicator" width={2} height={4} marginTop="1" />
            </a>
          </div>
        </div>
        <div className="text-white footer-unten">
          <div className="text-flicker p-1 font-mono">style error</div>
        </div>
        {/* <div className="text-white footer-linkl">
                <div className="pl-5 text-left text-3xl p-1">IMMAJUNG</div>
                <div className="pl-5 text-left p-1">Hello@immajung.de</div>
                <div className="pl-5 text-left p-1">am Kalkbruche 1b</div>
                <div className="pl-5 text-left p-1">30455 Hannover</div>
                <div className="pl-5 text-left p-1">0511-783868</div>
            </div> */}
        <div className="text-blueScreenBgInverted footer-social">
          <div className=" text-xl p-1">Follow</div>
          <div className="flex">
            <a target="_blank" rel="noopener noreferrer" href="" title="facebook">
              <img className="hover-svg svg-size h-6 w-6 ml-2 mr-3" src={facebook} alt="facebook logo" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/immajung.clo/"
              title="instagram"
            >
              <img className="hover-svg h-6 w-6 svg-size" src={instagram} alt="instagram logo" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
