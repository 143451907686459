import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'

export const Cookie: React.FC = () => {
  return (
    <CookieConsent
      enableDeclineButton
      acceptOnScroll={true}
      cookieName="gatsby-gdpr-google-analytics"
      location="top"
      buttonText="Accept"
      declineButtonText="Decline"
      style={{
        background: 'white',
        color: '#0000FF',
        border: 'solid 3px #0000FF',
        zIndex: '10',
      }}
      buttonStyle={{
        background: '#0000FF',
        color: 'white',
      }}
    >
      This website uses cookies to enhance the user experience. You can read more{' '}
      <Link to="/privacy" title="to privacy page">
        here.
      </Link>
    </CookieConsent>
  )
}
